import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Divider,
  Typography,
  IconButton,
  useMediaQuery,
  Toolbar,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate } from "react-router-dom";
import MapView from "../map/core/MapView";
import MapCurrentLocation from "../map/MapCurrentLocation";
import MapGeoMarketingEdit from "../map/mapGeoMarketingEdit";
import GeomarketingsList from "./GeomarketingList";
import { useTranslation } from "../common/components/LocalizationProvider";
import MapGeocoder from "../map/geocoder/MapGeocoder";
import { errorsActions } from "../store";
import MapRoutePath from "../map/MapRoutePath";
import MapMarkers from "../map/MapMarkers";
import MapCamera from "../map/MapCamera";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  drawerPaper: {
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      width: theme.dimensions.drawerWidthTablet,
    },
    [theme.breakpoints.down("sm")]: {
      height: theme.dimensions.drawerHeightPhone,
    },
  },
  mapContainer: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  fileInput: {
    display: "none",
  },
}));

const GeoMarketingsPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation();

  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedGeomarketingId, setSelectedGeomarketingId] = useState();
  const [route, setRoute] = useState(null);
  const [geomarketing, setGeomarketing] = useState();
  const handleFile = (event) => {
    const files = Array.from(event.target.files);
    const [file] = files;
    const reader = new FileReader();
    reader.onload = async () => {
      const xml = new DOMParser().parseFromString(reader.result, "text/xml");
      const segment = xml.getElementsByTagName("trkseg")[0];
      const coordinates = Array.from(segment.getElementsByTagName("trkpt"))
        .map(
          (point) => `${point.getAttribute("lat")} ${point.getAttribute("lon")}`
        )
        .join(", ");
      const area = `LINESTRING (${coordinates})`;
      const newItem = { name: "", area };
      try {
        const response = await fetch("/api/geofences", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newItem),
        });
        if (response.ok) {
          const item = await response.json();
          navigate(`/settings/geofence/${item.id}`);
        } else {
          throw Error(await response.text());
        }
      } catch (error) {
        dispatch(errorsActions.push(error.message));
      }
    };
    reader.onerror = (event) => {
      dispatch(errorsActions.push(event.target.error));
    };
    reader.readAsText(file);
  };

  const DrawPosition = (position) => {
    setRoute(position);
  };

  const setGeomarketingHandler = (position) => {
    setGeomarketing(position);
  };

  const createMarkers = () => {
    const marker = route["geometry"]["coordinates"];
    return [
      {
        latitude: marker[0][1],
        longitude: marker[0][0],
        color: "negative",
      },
      {
        latitude: marker[marker.length - 1][1],
        longitude: marker[marker.length - 1][0],
        color: "positive",
      },
    ];
  };
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Drawer
          anchor={isPhone ? "bottom" : "left"}
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              sx={{ mr: 2 }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("settingsGeoMarketing")}
            </Typography>
    {/*         <label htmlFor="upload-gpx">
              <input
                accept=".gpx"
                id="upload-gpx"
                type="file"
                className={classes.fileInput}
                onChange={handleFile}
              />
              <IconButton edge="end" component="span" onClick={() => {}}>
                <UploadFileIcon />
              </IconButton>
            </label> */}
          </Toolbar>
          
          <Divider />
          <GeomarketingsList
            onGeomarketingSelected={setSelectedGeomarketingId}
          />
        </Drawer>
        <div className={classes.mapContainer}>
          <MapView>
            <MapGeoMarketingEdit
              selectedGeomarketingId={selectedGeomarketingId}
              onPositions={DrawPosition}
              setGeomarketing={setGeomarketingHandler}
            />

            {route && (
              <>
                <MapRoutePath
                  positions={route}
                  geomarketing={true}
                  selectedGeomarketing={geomarketing.id}
                />
                <MapMarkers markers={createMarkers()} />
                <MapCamera
                  positions={route["geometry"]["coordinates"]}
                  geomarketing={true}
                />
              </>
            )}
          </MapView>
          <MapCurrentLocation />
          <MapGeocoder />
        </div>
      </div>
    </div>
  );
};

export default GeoMarketingsPage;
