import { useState, useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import { map } from './core/MapView';
import carSvg from '../resources/images/icon/car.svg';

const MapRoutepopup = ({ showRoute, positions, removePopup }) => {
  const [popup, setPopup] = useState(null);

  const secondToTime = (sec) => {
    var sec_num = parseInt(sec, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ' hr ' + minutes + ' min';
  };

  useEffect(() => {
    if (showRoute) {
      let coordinates = positions['geometry']['coordinates'];

      if (popup != null) {
        popup.remove();
        removePopup(null);
        setPopup(null);
      }
      const middleValue = parseInt(coordinates.length / 2);
      const infoWindow = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: false,
        closeOnMove: false,
      })
        .setLngLat(coordinates[middleValue])
        .setHTML(
          `<div > 
         <img  src=${carSvg} alt="car icon" width="20" height="20"/> <span style="color:#5a9d00 ;  font-size: 20px;
         font-weight: bold;
         "> ${secondToTime(positions['duration'])} </span>
          <br />
           <span> ${(positions['distance'] / 1000).toFixed(
             2
           )} km </span> </div>`
        )
        .addTo(map);

      setPopup(infoWindow);
      removePopup(infoWindow);
    } else {
      if (popup != null) {
        popup.remove();
        removePopup(null);
        setPopup(null);
      }
    }
  }, [showRoute, positions]);

  return null;
};

export default MapRoutepopup;
