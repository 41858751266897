import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import DirectionsIcon from '@mui/icons-material/Directions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PendingIcon from '@mui/icons-material/Pending';

import { useTranslation } from '../common/components/LocalizationProvider';
import RemoveDialog from '../common/components/RemoveDialog';
import PositionValue from '../common/components/PositionValue';
import { useDeviceReadonly, useRestriction } from '../common/util/permissions';

import { geomarketingsActions, errorsActions } from '../store';
import { useCatch } from '../reactHelper';
import GeomarketingDeviceDialog from '../settings/components/GeomarketingDeviceDialog';

const useStyles = makeStyles((theme) => ({
  card: {
    width: theme.dimensions.popupMaxWidth,
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 2),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    borderBottom: 'none',
  },
  actions: {
    justifyContent: 'space-between',
  },
}));

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">
          {content}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const StatusCardGeoMarketing = ({ geomarketingId, onClose, onRoute }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const [showDeviceList, setShowdeviceList] = useState(false);
  const geomarketing = useSelector(
    (state) => state.geomarketings.items[geomarketingId]
  );
  const readonly = useRestriction('readonly');
  const deviceReadonly = useDeviceReadonly();
  const positionItems = ['name', 'matricule', 'contact', 'phone', 'addresse'];

  const [anchorEl, setAnchorEl] = useState(null);

  const [removing, setRemoving] = useState(false);

  const handleRemove = useCatch(async (removed) => {
   if (removed) {
      const response = await fetch('/api/geomarketings');
      if (response.ok) {
        dispatch(geomarketingsActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });

  const routeHandler = async (departX, departY, definition) => {
    var stringOfPosition = '';
    definition.forEach((element) => {
      stringOfPosition = `${stringOfPosition}${element.longitude},${element.latitude};`;
    });
    stringOfPosition = stringOfPosition.slice(0, -1);
    try {
      const response = await fetch(
        `https://osrm.emkatech.tn/route/v1/driving/${departX},${departY};${stringOfPosition}?overview=full&alternatives=true&steps=true&geometries=geojson`
      );
      if (response.ok) {
        var responseJson = await response.json();
        onRoute(responseJson['routes'][0], geomarketing);
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      dispatch(errorsActions.push(error.message));
    }
  };
  const handleAddResult = (definition) => {
    setShowdeviceList(false);

    if (definition != null) {
      routeHandler(
        geomarketing.longitude,
        geomarketing.latitude,
        definition.selectedDeviceId
      );
    }
  };
  return (
    <>
      {geomarketing && (
        <Draggable handle={`.${classes.media}, .${classes.header}`}>
          <Card elevation={3} className={classes.card}>
            {
           <div className={classes.header}>
                <Typography variant="body2" color="textSecondary">
                  {geomarketing.name}
                </Typography>
                <IconButton
                  size="small"
                  onClick={onClose}
                  onTouchStart={onClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            }

            <CardContent className={classes.content}>
              <Table size="small" classes={{ root: classes.table }}>
                <TableBody>
                  {positionItems.map((key) => (
                    <StatusRow
                      key={key}
                      name={key}
                      content={
                        <PositionValue
                          position={geomarketing}
                          property={
                            geomarketing.hasOwnProperty(key) ? key : null
                          }
                          attribute={
                            geomarketing.hasOwnProperty(key) ? null : key
                          }
                        />
                      }
                    />
                  ))}
                </TableBody>
              </Table>
            </CardContent>

            <CardActions classes={{ root: classes.actions }} disableSpacing>
              <IconButton
                color="secondary"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <PendingIcon />
              </IconButton>

              <IconButton
                onClick={() => setShowdeviceList(true)}
                disabled={readonly}
              >
                <DirectionsIcon />
              </IconButton>
              <IconButton
                onClick={() =>
                  navigate(`/settings/geomarketing/${geomarketing?.id}`)
                }
                disabled={deviceReadonly}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => setRemoving(true)}
                disabled={deviceReadonly}
                className={classes.negative}
              >
                <DeleteIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Draggable>
      )}
      {showDeviceList && (
        <GeomarketingDeviceDialog
          open={showDeviceList}
          onResult={handleAddResult}
        />
      )}
      {
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            component="a"
            target="_blank"
            href={`https://www.google.com/maps/search/?api=1&query=${geomarketing?.latitude}%2C${geomarketing?.longitude}`}
          >
            <Typography color="secondary"> {t('linkGoogleMaps')}</Typography>
          </MenuItem>
          <MenuItem
            component="a"
            target="_blank"
            href={`http://maps.apple.com/?ll=${geomarketing?.latitude},${geomarketing?.longitude}`}
          >
            {t('linkAppleMaps')}
          </MenuItem>
          <MenuItem
            component="a"
            target="_blank"
            href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${geomarketing?.latitude}%2C${geomarketing?.longitude}`}
          >
            {t('linkStreetView')}
          </MenuItem>
        </Menu>
      }
      <RemoveDialog
        open={removing}
        endpoint="geomarketings"
        itemId={geomarketing?.id}
        onResult={(removed) => handleRemove(removed)}
      />
    </>
  );
};

export default StatusCardGeoMarketing;
