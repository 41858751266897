import palette from "../../common/theme/palette";
import { loadImage, prepareIcon } from "./mapUtil";

import directionSvg from "../../resources/images/direction.svg";
import backgroundSvg from "../../resources/images/background.svg";
import animalSvg from "../../resources/images/icon/animal.svg";
import bicycleSvg from "../../resources/images/icon/bicycle.svg";
import boatSvg from "../../resources/images/icon/boat.svg";
import busSvg from "../../resources/images/icon/bus.svg";
import carSvg from "../../resources/images/icon/car.svg";
import craneSvg from "../../resources/images/icon/crane.svg";
import defaultSvg from "../../resources/images/icon/default.svg";
import helicopterSvg from "../../resources/images/icon/helicopter.svg";
import motorcycleSvg from "../../resources/images/icon/motorcycle.svg";
import offroadSvg from "../../resources/images/icon/offroad.svg";
import personSvg from "../../resources/images/icon/person.svg";
import pickupSvg from "../../resources/images/icon/pickup.svg";
import planeSvg from "../../resources/images/icon/plane.svg";
import scooterSvg from "../../resources/images/icon/scooter.svg";
import shipSvg from "../../resources/images/icon/ship.svg";
import tractorSvg from "../../resources/images/icon/tractor.svg";
import trainSvg from "../../resources/images/icon/train.svg";
import tramSvg from "../../resources/images/icon/tram.svg";
import trolleybusSvg from "../../resources/images/icon/trolleybus.svg";
import truckSvg from "../../resources/images/icon/truck.svg";
import vanSvg from "../../resources/images/icon/van.svg";
import markerSvg from "../../resources/images/icon/marker.svg";

import airportSvg from "../../resources/images/icon/geomarketing/airport.png";
import bankSvg from "../../resources/images/icon/geomarketing/bank.png";
import civicSvg from "../../resources/images/icon/geomarketing/civic.png";
import dotSvg from "../../resources/images/icon/geomarketing/dot.png";
import gasSvg from "../../resources/images/icon/geomarketing/gas.png";
import hospitalSvg from "../../resources/images/icon/geomarketing/hospital.png";
import parkingSvg from "../../resources/images/icon/geomarketing/parking.png";
import policeSvg from "../../resources/images/icon/geomarketing/police.png";
import postOfficeSvg from "../../resources/images/icon/geomarketing/postOffice.png";
import restaurantSvg from "../../resources/images/icon/geomarketing/restaurant.png";
import transitSvg from "../../resources/images/icon/geomarketing/transit.png";

import airport_pinletSvg from "../../resources/images/icon/geomarketing/pinlet/airport_pinlet.svg";
import bank_pinletSvg from "../../resources/images/icon/geomarketing/pinlet/bank_pinlet.svg";
import civic_pinletSvg from "../../resources/images/icon/geomarketing/pinlet/civic_pinlet.svg";
import dot_pinletSvg from "../../resources/images/icon/geomarketing/pinlet/dot_pinlet.svg";
import gas_pinletSvg from "../../resources/images/icon/geomarketing/pinlet/gas_pinlet.svg";
import hospital_pinletSvg from "../../resources/images/icon/geomarketing/pinlet/hospital_pinlet.svg";
import parking_pinletSvg from "../../resources/images/icon/geomarketing/pinlet/parking_pinlet.svg";
import police_pinletSvg from "../../resources/images/icon/geomarketing/pinlet/police_pinlet.svg";
import postOffice_pinletSvg from "../../resources/images/icon/geomarketing/pinlet/postOffice_pinlet.svg";
import restaurant_pinletSvg from "../../resources/images/icon/geomarketing/pinlet/restaurant_pinlet.svg";
import transit_pinletSvg from "../../resources/images/icon/geomarketing/pinlet/transit_pinlet.svg";

export const mapIcons = {
  animal: animalSvg,
  bicycle: bicycleSvg,
  boat: boatSvg,
  bus: busSvg,
  car: carSvg,
  crane: craneSvg,
  default: defaultSvg,
  helicopter: helicopterSvg,
  motorcycle: motorcycleSvg,
  offroad: offroadSvg,
  person: personSvg,
  pickup: pickupSvg,
  plane: planeSvg,
  scooter: scooterSvg,
  ship: shipSvg,
  tractor: tractorSvg,
  train: trainSvg,
  tram: tramSvg,
  trolleybus: trolleybusSvg,
  truck: truckSvg,
  van: vanSvg,
  marker: markerSvg,

  airport: airportSvg,
  bank: bankSvg,
  civic: civicSvg,
  dot: dotSvg,
  gas: gasSvg,
  hospital: hospitalSvg,
  parking: parkingSvg,
  police: policeSvg,
  postOffice: postOfficeSvg,
  restaurant: restaurantSvg,
  transit: transitSvg,

  airport_pinlet: airport_pinletSvg,
  bank_pinlet: bank_pinletSvg,
  civic_pinlet: civic_pinletSvg,
  dot_pinlet: dot_pinletSvg,
  gas_pinlet: gas_pinletSvg,
  hospital_pinlet: hospital_pinletSvg,
  parking_pinlet: parking_pinletSvg,
  police_pinlet: police_pinletSvg,
  postOffice_pinlet: postOffice_pinletSvg,
  restaurant_pinlet: restaurant_pinletSvg,
  transit_pinlet: transit_pinletSvg,
};

export const mapIconKey = (category) =>
  mapIcons.hasOwnProperty(category) ? category : "default";

export const mapImages = {};

export default async () => {
  const background = await loadImage(backgroundSvg);
  mapImages.background = await prepareIcon(background);
  mapImages.direction = await prepareIcon(await loadImage(directionSvg));
  await Promise.all(
    Object.keys(mapIcons).map(async (category) => {
      const results = [];
      ["primary", "positive", "negative", "neutral", "geomarketing"].forEach(
        (color) => {
          if (color == "geomarketing") {
            results.push(
              loadImage(mapIcons[category]).then((icon) => {
                mapImages[`${category}-${color}`] = prepareIcon(icon);
              })
            );
          } else {
            results.push(
              loadImage(mapIcons[category]).then((icon) => {
                mapImages[`${category}-${color}`] = prepareIcon(
                  background,
                  icon,
                  palette.colors[color]
                );
              })
            );
          }
        }
      );
      await Promise.all(results);
    })
  );
};
