import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Autocomplete,
} from '@mui/material';

import { createFilterOptions } from '@mui/material/useAutocomplete';
import { makeStyles } from '@mui/styles';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
}));

const DeviceDialog = ({ onResult }) => {
  const classes = useStyles();
  const t = useTranslation();

  const definitions = useSelector((state) => state.devices.items);
  const positions = useSelector((state) => state.positions.items);

  const filter = createFilterOptions({
    stringify: (option) => option.name,
  });

  let list = [];

  Object.entries(definitions).map(([key, value]) => {
    list.push(value);
  });

  const newList = { ...list };

  const options = Object.entries(newList).map(([key, value]) => {
    return {
      key,
      id: value.id,
      name: value.name,
    };
  });
  const [key, setKey] = useState();

  const [selectedDeviceId, SetselectedDeviceId] = useState();
  return (
    /*  <Autocomplete
      multiple
      onChange={(_, option) => {
        setKey(option);
        SetselectedDeviceId([]);
        let selectedDevices = [];
        option.forEach((element) => {
          selectedDevices.push(element.id);
          SetselectedDeviceId(selectedDevices);
          onResult(selectedDevices);
        });
      }}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.name === value.name}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue) {
          filtered.push({
            key: params.inputValue,
            name: params.inputValue,
          });
        }
        return filtered;
      }}
      options={options}
      getOptionLabel={(option) =>
        option && typeof option === 'object' ? option.name : option
      }
      renderOption={(props, option) => (
        <li {...props} key={props.id}>
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={t('sharedSearchDevices')} />
      )}
      freeSolo
    /> */
    <Autocomplete
      id="free-solo-demo"
      onChange={(_, option) => {
        const result = options.filter((device) => device.name === option);
        SetselectedDeviceId([result[0]?.id]);
        onResult([result[0]?.id]);
      }}
      freeSolo
      options={list.map((option) => option.name)}
      renderInput={(params) => (
        <TextField {...params} label={t('sharedSearchDevices')} />
      )}
    />
  );
};

export default DeviceDialog;
