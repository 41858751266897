import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { map } from "./core/MapView";
import { findFonts } from "./core/mapUtil";
import maplibregl from "maplibre-gl";
import { devicesActions } from "../store";

const MapLocations = ({
  onClick,
  locations,
  showLocations,
  selectLocationId,
}) => {
  const dispatch = useDispatch();

  const onMapClick = useCallback(
    (event) => {
      if (!event.defaultPrevented) {
        onClick();
      }
    },
    [onClick]
  );

  const onMarkerClick = useCallback(
    (event) => {
      event.preventDefault();
      const feature = event.features[0];
      const isDevice = feature.properties.deviceId ? true : false;
      const id = isDevice ? feature.properties.deviceId : feature.properties.id;
      if (onClick) {
        onClick(feature.properties.id, id, isDevice);
      }
    },
    [onClick]
  );

  useEffect(() => {
    map.getStyle().layers.forEach((layer) => {
      if (!isNaN(layer.id)) {
        map.removeLayer(layer.id);
        map.removeSource(layer.id);
      }
    });
    Object.values(locations).forEach((location) => {
      if (!map.getSource(location.id)) {
        map.addSource(location.id.toString(), {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [location.longitude, location.latitude],
            },
            properties: {
              id: location.id,
              name: location.name,
              type: location.type,
              color: "geomarketing",
              contact: location.contact,
              phone: location.phone,
              addresse: location.addresse,
              matricule: location.matricule,
              latitude: location.latitude,
              longitude: location.longitude,
            },
          },
        });

        map.addLayer({
          id: location.id.toString(),
          type: "symbol",
          source: location.id.toString(),
          layout: {
            "icon-image": "{type}-{color}",
            "text-size": 16,
            "text-field": "{name}",
            "text-font": findFonts(map),
            // "text-allow-overlap": true,
            "text-anchor": "left",
            "text-radial-offset": 1,
          },
          paint: {
            "text-halo-color": "white",
            "text-halo-width": 1,
            "text-color": "#EA4335",
          },
        });
        map.on("click", location.id.toString(), (e) => {
          dispatch(devicesActions.select(null));
          const canvas = map.getCanvas();
          const coordinates = e.features[0].geometry.coordinates.slice();
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          var sw = new maplibregl.LngLat(location.longitude, location.latitude);
          onMarkerClick(e);
          var bounds = coordinates.reduce(function (bounds, coord) {
            return bounds.extend(coord);
          }, new maplibregl.LngLatBounds(sw, sw));

          map.fitBounds(bounds, {
            padding: Math.min(canvas.width, canvas.height) * 0.1,
          });
        });

        map.on("mouseenter", location.id.toString(), (e) => {
          map.getCanvas().style.cursor = "pointer";
        });

        map.on("mouseleave", location.id.toString(), () => {
          map.getCanvas().style.cursor = "";
        });
      }
    });
  }, [locations, onMapClick, onMarkerClick]);

  useEffect(() => {
    if (showLocations) {
      map.getStyle().layers.forEach((layer) => {
        if (!isNaN(layer.id)) {
          map.setLayoutProperty(layer.id, "visibility", "visible");
        }
      });
    } else {
      map.getStyle().layers.forEach((layer) => {
        if (!isNaN(layer.id)) {
          map.setLayoutProperty(layer.id, "visibility", "none");
        }
      });
    }
  }, [showLocations]);

  return null;
};

export default MapLocations;
