import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import maplibregl from "maplibre-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import theme from "@mapbox/mapbox-gl-draw/src/lib/theme";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { map } from "./core/MapView";
import { errorsActions, geomarketingsActions } from "../store";
import { useCatchCallback } from "../reactHelper";
import { findFonts } from "./core/mapUtil";

import StatusCardGeoMarketing from "../main/StatusCardGeoMarketing";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

let draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    point: true,
    polygon: false,
    line_string: false,
    trash: true,
  },

  userProperties: true,
  styles: [
    ...theme,
    {
      id: "gl-draw-title",
      type: "symbol",
      filter: ["all"],
      layout: {
        "text-field": "{user_name}",
        "text-size": 12,
        "icon-image": "{category}-{color}",
      },
      paint: {
        "text-halo-color": "white",
        "text-halo-width": 1,
      },
    },
  ],
});

const useStyles = makeStyles((theme) => ({
  statusCard: {
    position: "fixed",
    zIndex: 5,
    [theme.breakpoints.up("md")]: {
      left: `calc(50% + ${theme.dimensions.drawerWidthDesktop} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down("md")]: {
      left: "50%",
      bottom: `calc(${theme.spacing(3)} + ${
        theme.dimensions.bottomBarHeight
      }px)`,
    },
    transform: "translateX(-50%)",
  },
}));
const MapGeoMarketingEdit = ({
  selectedGeomarketingId,
  onPositions,
  setGeomarketing,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [showPopup, setShowPopup] = useState(null);
  const geofences = useSelector((state) => state.geomarketings.items);

  const [firstPosition, SetfirstPosition] = useState(null);
  const [lastPosition, SetlastPosition] = useState(null);

  const refreshGeofences = useCatchCallback(async () => {
    if (firstPosition != null) {
      firstPosition.remove();
      SetfirstPosition(null);
    }
    if (lastPosition != null) {
      lastPosition.remove();
      SetlastPosition(null);
    }
    const response = await fetch("/api/geomarketings");
    if (response.ok) {
      dispatch(geomarketingsActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }, [dispatch]);

  useEffect(() => {
    refreshGeofences();
    map.addControl(draw, "top-left");
    return () => map.removeControl(draw);
  }, [refreshGeofences]);

  useEffect(() => {
    const listener = async (event) => {
      const feature = event.features[0];
      const newItem = {
        name: "",
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0],
      };
      draw.delete(feature.id);
      try {
        const response = await fetch("/api/geomarketings", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newItem),
        });
        if (response.ok) {
          const item = await response.json();
          navigate(`/settings/geomarketing/${item.id}`);
        } else {
          throw Error(await response.text());
        }
      } catch (error) {
        dispatch(errorsActions.push(error.message));
      }
    };

    map.on("draw.create", listener);
    return () => map.off("draw.create", listener);
  }, [dispatch, navigate]);

  useEffect(() => {
    const listener = async (event) => {
      const feature = event.features[0];
      try {
        const response = await fetch(`/api/geomarketings/${feature.id}`, {
          method: "DELETE",
        });

        if (response.ok) {
          refreshGeofences();
        } else {
          throw Error(await response.text());
        }
      } catch (error) {
        dispatch(errorsActions.push(error.message));
      }
    };

    map.on("draw.delete", listener);
    return () => map.off("draw.delete", listener);
  }, [dispatch, refreshGeofences]);

  useEffect(() => {
    const listener = async (event) => {
      const feature = event.features[0];
      const item = Object.values(geofences).find((i) => i.id === feature.id);
      if (item) {
        const updatedItem = {
          ...item,
          latitude: feature.geometry.coordinates[0],
          longitude: feature.geometry.coordinates[1],
        };
        try {
          const response = await fetch(`/api/geomarketings/${feature.id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedItem),
          });
          if (response.ok) {
            refreshGeofences();
          } else {
            throw Error(await response.text());
          }
        } catch (error) {
          dispatch(errorsActions.push(error.message));
        }
      }
    };

    map.on("draw.update", listener);
    return () => map.off("draw.update", listener);
  }, [dispatch, geofences, refreshGeofences]);

  useEffect(() => {
    map.getStyle().layers.forEach((layer) => {
      if (!isNaN(layer.id)) {
        map.removeLayer(layer.id);
        map.removeSource(layer.id);
      }
    });
    Object.values(geofences).forEach((geofence) => {
      if (!map.getSource(geofence.id)) {
        map.addSource(geofence.id.toString(), {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [geofence.longitude, geofence.latitude],
            },
            properties: {
              id: geofence.id,
              name: geofence.name,
              type: geofence.type,
              color: "geomarketing",
              contact: geofence.contact,
              phone: geofence.phone,
              addresse: geofence.addresse,
              matricule: geofence.matricule,
              latitude: geofence.latitude,
              longitude: geofence.longitude,
            },
          },
        });
        map.addLayer({
          id: geofence.id.toString(),
          type: "symbol",
          source: geofence.id.toString(),
          layout: {
            "icon-image": "{type}-{color}",
            "text-size": 16,
            "text-field": "{name}",
            "text-font": findFonts(map),
            // "text-allow-overlap": true,
            "text-anchor": "left",
            "text-radial-offset": 1,
          },
          paint: {
            "text-halo-color": "white",
            "text-halo-width": 1,
            "text-color": "#EA4335",
          },
        });
        map.on("click", geofence.id.toString(), (e) => {
          const canvas = map.getCanvas();
          const coordinates = e.features[0].geometry.coordinates.slice();
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          var sw = new maplibregl.LngLat(geofence.longitude, geofence.latitude);
          setShowPopup(geofence);

          var bounds = coordinates.reduce(function (bounds, coord) {
            return bounds.extend(coord);
          }, new maplibregl.LngLatBounds(sw, sw));

          map.fitBounds(bounds, {
            padding: Math.min(canvas.width, canvas.height) * 0.1,
          });
        });

        map.on("mouseenter", geofence.id.toString(), (e) => {
          map.getCanvas().style.cursor = "pointer";
        });

        map.on("mouseleave", geofence.id.toString(), () => {
          // popUpRef.current.remove();
          map.getCanvas().style.cursor = "";
        });
      }
    });
  }, [geofences]);

  useEffect(() => {
    if (selectedGeomarketingId) {
      const feature = map.getSource(selectedGeomarketingId);
      let coordinates = feature["_data"].geometry.coordinates;
      var sw = new maplibregl.LngLat(coordinates[0], coordinates[1]);
      var bounds = coordinates.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new maplibregl.LngLatBounds(sw, sw));
      const canvas = map.getCanvas();
      map.fitBounds(bounds, {
        padding: Math.min(canvas.width, canvas.height) * 0.1,
      });
      setShowPopup(feature["_data"].properties);
    }
  }, [selectedGeomarketingId]);

  const routeHandle = (props, geomarketing) => {
    onPositions(props);
    setGeomarketing(geomarketing);
  };

  return (
    showPopup && (
      <div className={classes.statusCard}>
        <StatusCardGeoMarketing
          geomarketingId={showPopup.id}
          onRoute={routeHandle}
          onClose={() => setShowPopup(null)}
        />
      </div>
    )
  );
};

export default MapGeoMarketingEdit;
