import { map } from './core/MapView';
import { useId, useEffect, useState } from 'react';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { useAttributePreference } from '../common/util/preferences';
import AddressValue from '../common/components/AddressValue';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

import makeStyles from '@mui/styles/makeStyles';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from '@mui/material';
import {
  formatDistance,
  formatHours,
  formatVolume,
  formatTime,
} from '../common/util/formatter';
import usePersistedState from '../common/util/usePersistedState';
import { useTranslation } from '../common/components/LocalizationProvider';
import { Style } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    width: '100%',
    height: '25%',
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    overflow: 'auto',
  },
  table: {
    width: '100%',
  },
}));

const columnsArray = [
  ['startTime', 'reportStartTime'],
  ['startOdometer', 'positionOdometer'],
  ['address', 'positionAddress'],
  ['endTime', 'reportEndTime'],
  ['duration', 'reportDuration'],
  ['engineHours', 'reportEngineHours'],
  ['spentFuel', 'reportSpentFuel'],
];
const columnsMap = new Map(columnsArray);

const MapStops = ({ stops }) => {
  const distanceUnit = useAttributePreference('distanceUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const [isActive, setIsActive] = useState(false);

  const id = useId();
  const [columns, setColumns] = usePersistedState('stopColumns', [
    'startTime',
    'endTime',
    'startOdometer',
    'address',
  ]);
  const t = useTranslation();

  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState(null);

  const handleMouseEnter = () => {
    setIsActive('rgba(255, 255, 255,1)');
  };

  const handleMouseLeave = () => {
    setIsActive('rgba(255, 255, 255, 0.5)');
  };

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
    map.addLayer({
      id,
      type: 'circle',
      source: id,
      /* layout: {
        'icon-image': '{category}-{color}',
        'icon-allow-overlap': true,
      },*/
      paint: {
        'circle-radius': 10,
        'circle-color': '#bf0020',
      },
    });

    return () => {
      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, []);

  useEffect(() => {
    map.getSource(id).setData({
      type: 'FeatureCollection',
      features: stops.map(({ latitude, longitude }) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
        properties: {},
      })),
    });
  }, [stops]);

  const formatValue = (item, key) => {
    switch (key) {
      case 'startTime':
      case 'endTime':
        return formatTime(item[key], 'YYYY-MM-DD HH:mm');
      case 'startOdometer':
        return formatDistance(item[key], distanceUnit, t);
      case 'duration':
        return formatHours(item[key]);
      case 'engineHours':
        return formatHours(item[key]);
      case 'spentFuel':
        return formatVolume(item[key], volumeUnit, t);
      case 'address':
        return (
          <AddressValue
            latitude={item.latitude}
            longitude={item.longitude}
            originalAddress={item[key]}
          />
        );
      default:
        return item[key];
    }
  };

  return (
    <div
      className={classes.container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ backgroundColor: isActive }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.columnAction} />
              {columns.map((key) => (
                <TableCell key={key}>{t(columnsMap.get(key))}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stops.map((item) => (
              <TableRow key={item.positionId}>
                <TableCell className={classes.columnAction} padding="none">
                  {selectedItem === item ? (
                    <IconButton
                      size="small"
                      onClick={() => setSelectedItem(null)}
                    >
                      <GpsFixedIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedItem(item);

                        map.flyTo({
                          center: [item.longitude, item.latitude],
                          zoom: 24,
                        });
                      }}
                    >
                      <LocationSearchingIcon fontSize="small" />
                    </IconButton>
                  )}
                </TableCell>
                {columns.map((key) => (
                  <TableCell key={key}>{formatValue(item, key)}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MapStops;
