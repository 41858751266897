import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Autocomplete,
} from "@mui/material";

import { createFilterOptions } from "@mui/material/useAutocomplete";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "../../common/components/LocalizationProvider";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
}));

const GeomarketingDeviceDialog = ({ open, onResult }) => {
  const classes = useStyles();
  const t = useTranslation();
  const definitions = useSelector((state) => state.devices.items);
  const positions = useSelector((state) => state.positions.items);
  const geomarketings = useSelector((state) => state.geomarketings.items);
  const filter = createFilterOptions({
    stringify: (option) => option.name,
  });

  let list = [];

  Object.entries(definitions).map(([key, value]) => {
    list.push(value);
  });

  Object.entries(geomarketings).map(([key, value]) => {
    list.push(value);
  });
  const newList = { ...list };

  const options = Object.entries(newList).map(([key, value]) => {
    return {
      key,
      id: value.id,
      name: value.name,
      class: value.uniqueId ? "device" : "geomarketing",
      longitude: value.longitude,
      latitude: value.latitude,
    };
  });
  const [key, setKey] = useState();

  const [selectedDeviceId, SetselectedDeviceId] = useState();
  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogContent className={classes.details}>
        <Autocomplete
          multiple
          clearOnEscape
          onChange={(_, option) => {
            setKey(option);
            SetselectedDeviceId([]);
            let selectedDevices = [];
            option.forEach((element) => {
              if (element.class == "device") {
                Object.values(positions).map((item) => {
                  if (item.deviceId == element.id) {
                    selectedDevices.push({
                      id: element.id,
                      latitude: item.latitude,
                      longitude: item.longitude,
                      name: element.name,
                      address: item.address,
                    });
                  }
                });
              } else {
                selectedDevices.push({
                  id: element.id,
                  latitude: element.latitude,
                  longitude: element.longitude,
                  name: element.name,
                  address: element.address,
                });
              }
              SetselectedDeviceId(selectedDevices);
            });
          }}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option.name === value.name}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue) {
              filtered.push({
                key: params.inputValue,
                name: params.inputValue,
              });
            }
            return filtered;
          }}
          options={options}
          getOptionLabel={(option) =>
            option && typeof option === "object" ? option.name : option
          }
          renderOption={(props, option) => (
            <li {...props} key={props.id}>
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label={t("sharedDevicesPlaces")} />
          )}
          freeSolo
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!key}
          onClick={() => {
              onResult({ key, selectedDeviceId });
          }}
        >
          {t("sharedAdd")}
        </Button>
        <Button autoFocus onClick={() => onResult(null)}>
          {t("sharedCancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GeomarketingDeviceDialog;
