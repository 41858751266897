import { useTheme } from '@mui/styles';
import { useId, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { map } from './core/MapView';

const MapRoutePath = ({ positions, geomarketing, selectedGeomarketing }) => {
  const id = useId();

  const theme = useTheme();

  const reportColor = useSelector((state) => {
    let location;
    if (geomarketing) {
      location = positions['geometry']['coordinates'];
    } else {
      location = positions;
    }
    const position = location.find(() => true);

    if (position) {
      let attributes = state.devices.items[position.deviceId]?.attributes;

      if (geomarketing) {
        attributes =
          state.geomarketings.items[selectedGeomarketing]?.attributes;
      }
      if (attributes) {
        const color = attributes['web.reportColor'];
        if (color) {
          return color;
        }
      }
    }
    return theme.palette.colors.geometry;
  });

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      },
    });
    map.addLayer({
      source: id,
      id,
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': ['get', 'color'],
        'line-width': 2,
      },
    });

    return () => {
      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, []);

  useEffect(() => {
    let coordinates;
    if (!geomarketing) {
      coordinates = positions.map((item) => [item.longitude, item.latitude]);
    } else {
      coordinates = positions['geometry']['coordinates'];
    }
    map.getSource(id).setData({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates,
      },
      properties: {
        color: reportColor,
      },
    });
  }, [positions, reportColor]);

  return null;
};

export default MapRoutePath;
