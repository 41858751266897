import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditItemView from "./components/EditItemView";
import EditAttributesAccordion from "./components/EditAttributesAccordion";
import { useTranslation } from "../common/components/LocalizationProvider";
import SettingsMenu from "./components/SettingsMenu";
import SelectField from "../common/components/SelectField";
import { geomarketingsActions } from "../store";

const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const GeomarketingPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const [item, setItem] = useState();

  const onItemSaved = (result) => {
    dispatch(geomarketingsActions.update([result]));
  };

  const validate = () => item && item.name && item.type;

  return (
    <EditItemView
      endpoint="geomarketings"
      item={item}
      setItem={setItem}
      validate={validate}
      onItemSaved={onItemSaved}
      menu={<SettingsMenu />}
      breadcrumbs={["settingsTitle", "settingsGeoMarketing"]}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedRequired")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ""}
                onChange={(event) =>
                  setItem({ ...item, name: event.target.value })
                }
                label={t("sharedName")}
              />
              <SelectField
                showEmptyValue={false}
                value={item.type || "dot"}
                onChange={(event) =>
                  setItem({ ...item, type: event.target.value })
                }
                data={[
                  { id: "dot", name: t("categoryDefault") },
                  { id: "bank", name: t("bank") },
                  { id: "gas", name: t("gas") },
                  { id: "parking", name: t("parking") },
                  { id: "postOffice", name: t("postOffice") },
                  { id: "police", name: t("police") },
                  { id: "airport", name: t("airport") },
                  { id: "civic", name: t("civic") },
                  { id: "hospital", name: t("hospital") },
                  { id: "restaurant", name: t("restaurant") },
                  { id: "transit", name: t("transit") },
                ]}
                label={t("sharedType")}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedExtra")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.matricule || ""}
                onChange={(event) =>
                  setItem({ ...item, matricule: event.target.value })
                }
                label={t("geoMarkitingMatricule")}
              />

              <TextField
                value={item.contact || ""}
                onChange={(event) =>
                  setItem({ ...item, contact: event.target.value })
                }
                label={t("deviceContact")}
              />
              <TextField
                value={item.phone || ""}
                onChange={(event) =>
                  setItem({ ...item, phone: event.target.value })
                }
                label={t("geoMarkitingphone")}
              />
              <TextField
                value={item.addresse || ""}
                label={t("geoMarkitingphone")}
                disabled
              />
              <SelectField
                value={item.calendarId || 0}
                onChange={(event) =>
                  setItem({ ...item, calendarId: Number(event.target.value) })
                }
                endpoint="/api/calendars"
                label={t("sharedCalendar")}
              />
            </AccordionDetails>
          </Accordion>
          <EditAttributesAccordion
            attributes={item.attributes}
            setAttributes={(attributes) => setItem({ ...item, attributes })}
            definitions={{
              "web.reportColor": {
                name: t("attributeWebReportColor"),
                type: "string",
                subtype: "color",
              },
            }}
          />
        </>
      )}
    </EditItemView>
  );
};

export default GeomarketingPage;
