import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Toolbar,
  IconButton,
  Button,
  OutlinedInput,
  InputAdornment,
  Popover,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Badge,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListIcon from '@mui/icons-material/ViewList';
import TuneIcon from '@mui/icons-material/Tune';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DevicesList from './DevicesList';
import MapView from '../map/core/MapView';
import MapSelectedDevice from '../map/main/MapSelectedDevice';
import MapAccuracy from '../map/main/MapAccuracy';
import MapGeofence from '../map/MapGeofence';
import MapCurrentLocation from '../map/MapCurrentLocation';
import BottomMenu from '../common/components/BottomMenu';
import { useTranslation } from '../common/components/LocalizationProvider';
import PoiMap from '../map/main/PoiMap';
import MapPadding from '../map/MapPadding';
import StatusCard from './StatusCard';
import StatusCardGeoMarketing from './StatusCardGeoMarketing';

import { devicesActions } from '../store';
import MapDefaultCamera from '../map/main/MapDefaultCamera';
import usePersistedState from '../common/util/usePersistedState';
import MapLiveRoutes from '../map/main/MapLiveRoutes';
import { useDeviceReadonly } from '../common/util/permissions';
import MapPositions from '../map/MapPositions';
import MapLocations from '../map/MapLocations';

import MapOverlay from '../map/overlay/MapOverlay';
import MapGeocoder from '../map/geocoder/MapGeocoder';
import MapScale from '../map/MapScale';
import MapNotification from '../map/notification/MapNotification';
import MapGeomarketingPlaces from '../map/geomarketing/placeGeomarketing/MapGeomarketingPlaces';

import MapGeomarketingPolyline from '../map/geomarketing/polyline/MapGeomarketingPolyline';

import EventsDrawer from './EventsDrawer';
import useFeatures from '../common/util/useFeatures';
import MapRoutePath from '../map/MapRoutePath';
import MapMarkers from '../map/MapMarkers';

import MapRoutepopup from '../map/MapRoutePopup';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 3,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    bottom: theme.dimensions.bottomBarHeight,
    transition: 'transform .5s ease',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  sidebarCollapsed: {
    transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(-100vw)',
    },
  },
  toolbarContainer: {
    zIndex: 4,
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  deviceList: {
    flex: 1,
  },
  statusCard: {
    position: 'fixed',
    zIndex: 5,
    [theme.breakpoints.up('md')]: {
      left: `calc(50% + ${theme.dimensions.drawerWidthDesktop} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      left: '50%',
      bottom: `calc(${theme.spacing(3)} + ${
        theme.dimensions.bottomBarHeight
      }px)`,
    },
    transform: 'translateX(-50%)',
  },
  sidebarToggle: {
    position: 'fixed',
    left: theme.spacing(1.5),
    top: theme.spacing(3),
    borderRadius: '0px',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  sidebarToggleBg: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.6)',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  bottomMenu: {
    position: 'fixed',
    left: theme.spacing(1.5),
    bottom: theme.spacing(1.5),
    zIndex: 4,
    width: theme.dimensions.drawerWidthDesktop,
  },
  filterPanel: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    width: theme.dimensions.drawerWidthTablet,
  },
}));

const MainPage = ({ init }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const t = useTranslation();

  const deviceReadonly = useDeviceReadonly();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const features = useFeatures();

  const [mapOnSelect] = usePersistedState('mapOnSelect', false);

  const [mapLiveRoutes] = usePersistedState('mapLiveRoutes', false);

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const user = useSelector((state) => state.session.user);
  const positions = useSelector((state) => state.positions.items);
  const [filteredPositions, setFilteredPositions] = useState([]);
  const selectedPosition = filteredPositions.find(
    (position) => selectedDeviceId && position.deviceId === selectedDeviceId
  );

  const groups = useSelector((state) => state.groups.items);
  const devices = useSelector((state) => state.devices.items);
  const locations = useSelector((state) => state.geomarketings.items);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [filteredLocation, setFilteredLocations] = useState([]);

  const [filterKeyword, setFilterKeyword] = useState('');
  const [filterStatuses, setFilterStatuses] = useState([]);
  const [filterGroups, setFilterGroups] = useState([]);
  const [filterSort, setFilterSort] = usePersistedState('filterSort', '');
  const [filterMap, setFilterMap] = usePersistedState('filterMap', false);

  const filterRef = useRef();
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const [devicesOpen, setDevicesOpen] = useState(false);
  const [eventsOpen, setEventsOpen] = useState(false);

  const [selectedLocationId, setSelectedlocationId] = useState(null);

  const [route, setRoute] = useState(null);
  const [showRoute, setShowRoute] = useState(false);

  const eventHandler = useCallback(() => setEventsOpen(true), [setEventsOpen]);

  const eventsAvailable = useSelector((state) => !!state.events.items.length);
  const [showLocations, setShowLocations] = useState(false);
  const [popup, setPopup] = useState(null);
  const [searchPlaceholder, setSearchplaceholder] = useState(
    'sharedSearchDevicesPlaces'
  );

  const LocationHandler = useCallback(() => {
    setShowLocations((prev) => !prev);
  }, [setShowLocations]);
  const PolylineHandler = useCallback(() => {
    setShowRoute((prev) => !prev);
  }, [setShowRoute]);

  const handleClose = () => {
    setDevicesOpen(!devicesOpen);
  };

  const [selectedGeomarking, setSelectedGeomarking] = useState(null);

  const deviceStatusCount = (status) =>
    Object.values(devices).filter((d) => d.status === status).length;

  const removePopup = (data) => {
    setPopup(data);
  };
  useEffect(() => setDevicesOpen(desktop), [desktop]);

  useEffect(() => {
    if (!desktop && mapOnSelect && selectedDeviceId) {
      setDevicesOpen(false);
    }
  }, [desktop, mapOnSelect, selectedDeviceId]);

  const onClick = useCallback(
    (_, deviceId, isDevice) => {
      setSelectedlocationId(null);

      if (isDevice) {
        dispatch(devicesActions.select(deviceId));
      } else {
        setSelectedlocationId(deviceId);
      }
    },
    [dispatch, setSelectedlocationId]
  );

  const deviceGroups = (device) => {
    const groupIds = [];
    let { groupId } = device;
    while (groupId) {
      groupIds.push(groupId);
      groupId = groups[groupId].groupId;
    }
    return groupIds;
  };

  useEffect(() => {
    const filtered = Object.values(devices)
      .filter(
        (device) =>
          !filterStatuses.length || filterStatuses.includes(device.status)
      )
      .filter(
        (device) =>
          !filterGroups.length ||
          deviceGroups(device).some((id) => filterGroups.includes(id))
      )
      .filter((device) => {
        const keyword = filterKeyword.toLowerCase();
        return [
          device.name,
          device.uniqueId,
          device.phone,
          device.model,
          device.contact,
        ].some((s) => s && s.toLowerCase().includes(keyword));
      });
    switch (filterSort) {
      case 'name':
        filtered.sort((device1, device2) =>
          device1.name.localeCompare(device2.name)
        );
        break;
      case 'lastUpdate':
        filtered.sort((device1, device2) => {
          const time1 = device1.lastUpdate
            ? moment(device1.lastUpdate).valueOf()
            : 0;
          const time2 = device2.lastUpdate
            ? moment(device2.lastUpdate).valueOf()
            : 0;
          return time2 - time1;
        });
        break;
      default:
        break;
    }
    if (filterSort === 'lastUpdate') {
      filtered.sort((device1, device2) => {
        const time1 = device1.lastUpdate
          ? moment(device1.lastUpdate).valueOf()
          : 0;
        const time2 = device2.lastUpdate
          ? moment(device2.lastUpdate).valueOf()
          : 0;
        return time2 - time1;
      });
    }
    setFilteredDevices(filtered);
    setFilteredPositions(
      filterMap
        ? filtered.map((device) => positions[device.id]).filter(Boolean)
        : Object.values(positions)
    );
  }, [
    devices,
    positions,
    filterKeyword,
    filterStatuses,
    filterGroups,
    filterSort,
    filterMap,
  ]);

  useEffect(() => {
    const filtered = Object.values(locations)
      .filter(
        (location) =>
          !filterStatuses.length || filterStatuses.includes(location.status)
      )
      .filter(
        (location) =>
          !filterGroups.length ||
          deviceGroups(location).some((id) => filterGroups.includes(id))
      )
      .filter((location) => {
        const keyword = filterKeyword.toLowerCase();
        return [
          location.name,
          location.matricule,
          location.phone,
          location.type,
          location.contact,
        ].some((s) => s && s.toLowerCase().includes(keyword));
      });
    switch (filterSort) {
      case 'name':
        filtered.sort((location1, location2) =>
          location1.name.localeCompare(location2.name)
        );
        break;
      default:
        break;
    }
    setFilteredLocations(filtered);
  }, [locations, filterKeyword, filterStatuses, filterGroups, filterSort]);

  const routeHandle = (props, geomarketing) => {
    setRoute(props);
    setShowRoute(true);
    setSelectedGeomarking(geomarketing);
    dispatch(devicesActions.select(null));
    setSelectedlocationId(null);
  };

  const createMarkers = () => {
    const marker = route['geometry']['coordinates'];
    return [
      {
        latitude: marker[0][1],
        longitude: marker[0][0],
        color: 'negative',
      },
      {
        latitude: marker[marker.length - 1][1],
        longitude: marker[marker.length - 1][0],
        color: 'positive',
      },
    ];
  };

  useEffect(() => {
    if (user?.attributes['pushoverDeviceNames'] === 'navire') {
      setSearchplaceholder('sharednavire');
    }
  }, []);
  return (
    <div className={classes.root}>
      <MapView>
        <MapOverlay />
        <MapGeofence />
        <MapAccuracy positions={filteredPositions} />
        {mapLiveRoutes && <MapLiveRoutes />}
        {route && showRoute && (
          <>
            <MapMarkers markers={createMarkers()} />
            <MapRoutePath
              positions={route}
              geomarketing={true}
              selectedGeomarketing={selectedGeomarking.id}
            />
          </>
        )}
        <MapRoutepopup
          showRoute={showRoute}
          positions={route}
          removePopup={removePopup}
        />
        <MapPositions
          positions={filteredPositions}
          onClick={onClick}
          selectedPosition={selectedPosition}
          showStatus
        />

        <MapLocations
          onClick={onClick}
          locations={filteredLocation}
          showLocations={showLocations}
          selectLocationId={selectedLocationId}
        />
        <MapDefaultCamera />
        <MapSelectedDevice
          selectedLocationId={selectedLocationId}
          showPlace={() => {
            if (!showLocations) {
              setShowLocations(true);
            }
          }}
        />
        <PoiMap />
      </MapView>
      <MapScale />
      <MapCurrentLocation />
      <MapGeocoder />
      <MapGeomarketingPlaces enabled={true} onClick={LocationHandler} />
      {route && (
        <MapGeomarketingPolyline enabled={true} onClick={PolylineHandler} />
      )}
      {!features.disableEvents && (
        <MapNotification enabled={eventsAvailable} onClick={eventHandler} />
      )}
      {desktop && (
        <MapPadding left={parseInt(theme.dimensions.drawerWidthDesktop, 10)} />
      )}
      <Button
        variant="contained"
        color={phone ? 'secondary' : 'primary'}
        classes={{ containedPrimary: classes.sidebarToggleBg }}
        className={classes.sidebarToggle}
        onClick={handleClose}
        disableElevation
      >
        <ListIcon />
        <div className={classes.sidebarToggleText}>{t('deviceTitle')}</div>
      </Button>
      <Paper
        square
        elevation={3}
        className={`${classes.sidebar} ${
          !devicesOpen && classes.sidebarCollapsed
        }`}
      >
        <Paper square elevation={3} className={classes.toolbarContainer}>
          <Toolbar className={classes.toolbar} disableGutters>
            {!desktop && (
              <IconButton edge="start" sx={{ mr: 2 }} onClick={handleClose}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <OutlinedInput
              ref={filterRef}
              placeholder={t(searchPlaceholder)}
              value={filterKeyword}
              onChange={(event) => setFilterKeyword(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    edge="end"
                    onClick={() => setFilterAnchorEl(filterRef.current)}
                  >
                    <Badge
                      color="info"
                      variant="dot"
                      invisible={!filterStatuses.length && !filterGroups.length}
                    >
                      <TuneIcon fontSize="small" />
                    </Badge>
                  </IconButton>
                </InputAdornment>
              }
              size="small"
              fullWidth
            />
            <Popover
              open={!!filterAnchorEl}
              anchorEl={filterAnchorEl}
              onClose={() => setFilterAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <div className={classes.filterPanel}>
                <FormControl>
                  <InputLabel>{t('deviceStatus')}</InputLabel>
                  <Select
                    label={t('deviceStatus')}
                    value={filterStatuses}
                    onChange={(e) => setFilterStatuses(e.target.value)}
                    multiple
                  >
                    <MenuItem value="online">{`${t(
                      'deviceStatusOnline'
                    )} (${deviceStatusCount('online')})`}</MenuItem>
                    <MenuItem value="offline">{`${t(
                      'deviceStatusOffline'
                    )} (${deviceStatusCount('offline')})`}</MenuItem>
                    <MenuItem value="unknown">{`${t(
                      'deviceStatusUnknown'
                    )} (${deviceStatusCount('unknown')})`}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>{t('settingsGroups')}</InputLabel>
                  <Select
                    label={t('settingsGroups')}
                    value={filterGroups}
                    onChange={(e) => setFilterGroups(e.target.value)}
                    multiple
                  >
                    {Object.values(groups)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>{t('sharedSortBy')}</InputLabel>
                  <Select
                    label={t('sharedSortBy')}
                    value={filterSort}
                    onChange={(e) => setFilterSort(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="">{'\u00a0'}</MenuItem>
                    <MenuItem value="name">{t('sharedName')}</MenuItem>
                    <MenuItem value="lastUpdate">
                      {t('deviceLastUpdate')}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterMap}
                        onChange={(e) => setFilterMap(e.target.checked)}
                      />
                    }
                    label={t('sharedFilterMap')}
                  />
                </FormGroup>
              </div>
            </Popover>
            <IconButton
              onClick={() => navigate('/settings/device')}
              disabled={deviceReadonly}
            >
              <AddIcon />
            </IconButton>
            {desktop && (
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Toolbar>
        </Paper>
        <div className={classes.deviceList}>
          <DevicesList
            devices={filteredDevices}
            locations={filteredLocation}
            selectLocationId={(id) => {
              setSelectedlocationId(id);
            }}
          />
        </div>
      </Paper>

      {desktop && (
        <div className={classes.bottomMenu}>
          <BottomMenu removeallpopup={popup} />
        </div>
      )}
      {!features.disableEvents && (
        <EventsDrawer open={eventsOpen} onClose={() => setEventsOpen(false)} />
      )}
      {selectedDeviceId && (
        <div className={classes.statusCard}>
          <StatusCard
            onRoute={routeHandle}
            deviceId={selectedDeviceId}
            removeallpopup={popup}
            onClose={() => {
              dispatch(devicesActions.select(null));
              setSelectedlocationId(null);
            }}
          />
        </div>
      )}
      {selectedLocationId && (
        <div className={classes.statusCard}>
          <StatusCardGeoMarketing
            geomarketingId={selectedLocationId}
            onRoute={routeHandle}
            onClose={() => {
              setSelectedlocationId(null);
              dispatch(devicesActions.select(null));
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MainPage;
